
import Vue from "vue";
import KMenu, { MenuItem } from "@/components/KMenu.vue";
import { mapGetters } from "vuex";
import { UserLevel } from "@/modules/product/enum/UserLevel";

const filterMenuItemsOnLevel = (
  menuItems: MenuItem[],
  userLevel: UserLevel
): MenuItem[] => {
  return menuItems
    .filter((item) => item.level.includes(userLevel))
    .map((item) => ({
      ...item,
      children: item.children?.length
        ? filterMenuItemsOnLevel(item.children, userLevel)
        : [],
    }));
};

const hexspoorOnlyLevels = [UserLevel.Administrator, UserLevel.Hexspoor];
const allRegularLevels = hexspoorOnlyLevels.concat(UserLevel.Default);
const allLevels = allRegularLevels.concat([UserLevel.Exit, UserLevel.Supplier]);

export default Vue.extend({
  name: "TheAuthorisedMenu",
  components: { KMenu },
  computed: {
    ...mapGetters("authorisation", ["userLevel"]),
    menuItems(): MenuItem[] {
      return filterMenuItemsOnLevel(this.items, this.userLevel);
    },
    items(): MenuItem[] {
      return Object.values({
        salesOrders: {
          icon: "$cart",
          title: this.$t("menu.salesOrderIndex") as string,
          level: allRegularLevels.concat(UserLevel.Exit),
          children: [
            {
              title: this.$t("menu.salesOrder.create") as string,
              route: { name: "salesOrder.create" },
              level: allRegularLevels,
            },
            {
              title: this.$t("menu.salesOrder.concept") as string,
              route: { name: "salesOrder.concept" },
              level: allRegularLevels,
            },
            {
              title: this.$t("menu.salesOrder.overview") as string,
              route: { name: "salesOrder.overview" },
              level: allRegularLevels.concat(UserLevel.Exit),
              exact: true,
            },
            {
              title: this.$t("menu.salesOrder.addressBook") as string,
              route: { name: "addressBook.index" },
              level: allRegularLevels,
            },
          ],
        },
        products: {
          icon: "$dots",
          title: this.$t("menu.productIndex") as string,
          level: allRegularLevels.concat(UserLevel.Exit),
          children: [
            {
              title: this.$t("menu.product.create") as string,
              route: { name: "product.create" },
              level: [UserLevel.Administrator],
              exact: true,
            },
            {
              title: this.$t("menu.product.importGuide") as string,
              route: { name: "product.importGuide" },
              level: allRegularLevels,
              exact: true,
            },
            {
              title: this.$t("menu.product.concept") as string,
              route: { name: "product.concept" },
              level: [UserLevel.Administrator],
              exact: true,
            },
            {
              title: this.$t("menu.product.overview") as string,
              route: { name: "product.index" },
              level: allRegularLevels.concat(UserLevel.Exit),
              exact: true,
            },
          ],
        },
        stock: {
          icon: "$box",
          title: this.$t("menu.stockIndex") as string,
          level: allRegularLevels.concat(UserLevel.Exit),
          children: [
            {
              title: this.$t("menu.stock.overview") as string,
              route: { name: "stock.index" },
              level: allRegularLevels.concat(UserLevel.Exit),
              exact: true,
            },
            {
              title: this.$t("menu.stock.flow") as string,
              route: { name: "stock.flow" },
              level: allRegularLevels.concat(UserLevel.Exit),
              exact: true,
            },
            {
              title: this.$t("menu.stock.logFin") as string,
              route: { name: "stock.logFin" },
              level: allRegularLevels.concat(UserLevel.Exit),
              exact: true,
            },
            {
              title: this.$t("menu.stock.batchReport") as string,
              route: { name: "stock.batchReport" },
              level: allRegularLevels.concat(UserLevel.Exit),
              exact: true,
            },
          ],
        },
        deliveries: {
          icon: "$ship",
          title: this.$t("menu.deliveriesIndex") as string,
          level: allRegularLevels.concat(UserLevel.Supplier),
          children: [
            {
              title: this.$t("menu.deliveries.create") as string,
              route: { name: "delivery.concept.create" },
              level: hexspoorOnlyLevels.concat(UserLevel.Supplier),
            },
            {
              title: this.$t("menu.deliveries.concept") as string,
              route: { name: "delivery.concept" },
              level: hexspoorOnlyLevels,
            },
            {
              title: this.$t("menu.deliveries.overview") as string,
              route: { name: "delivery.index" },
              level: hexspoorOnlyLevels,
            },
            {
              title: this.$t("menu.deliveries.receipt") as string,
              route: { name: "delivery.receipt" },
              level: allRegularLevels,
            },
          ],
        },
        reporting: {
          icon: "$retour",
          title: this.$t("menu.returnsIndex") as string,
          level: allRegularLevels,
          children: [
            {
              title: this.$t("menu.returns.overview") as string,
              route: { name: "returns.index" },
              level: allRegularLevels,
            },
          ],
        },
        support: {
          icon: "$headset",
          title: this.$t("menu.supportIndex") as string,
          level: allLevels,
          children: [
            {
              title: this.$t("menu.support.contact") as string,
              link: "https://www.hexspoorfulfilment.nl/contact/",
              level: allLevels,
              target: "_blank",
            },
            {
              title: this.$t("menu.support.website") as string,
              link: "https://www.hexspoorfulfilment.nl/",
              level: allLevels,
              target: "_blank",
            },
          ],
        },
        settings: {
          icon: "$gear",
          title: this.$t("menu.settingsIndex") as string,
          level: [UserLevel.Administrator, UserLevel.Hexspoor],
          children: [
            {
              icon: "$users",
              title: this.$t("menu.users") as string,
              route: { name: "user.index" },
              level: [UserLevel.Administrator, UserLevel.Hexspoor],
            },
          ],
        },
      });
    },
  },
});
